export const ORDER_STATUS= {
    WAITING:0,
    CONFIRM:1,
    PREPARING:2,
    ONTHEWAY:3,
    DELIVERED:4,
    CANCELLED:5
}

export const ORDER_ASSIGNED={
    YES:1,
    NO:0
}