import { React,useState,useEffect} from 'react';
import {BrowserRouter as Router,Routes,Route,Redirect} from 'react-router-dom';
import HomePage from './component/Homepage';
import Tracking from './component/Tracking';
import { FreeCameraOptions } from 'mapbox-gl';

const App = () => {

  return (
   <Router>
    <Routes>
      <Route exact path="/:id" element={<Tracking/>}/>
    </Routes>
   </Router>

  );
};

export default App;
