import driver from './images/driver.png';

const DeliverySuccess =(props)=>{
    return(
        <div class="bg1 space-y-1 m-4 rounded-lg ">
        <div class="flextext-black pt-4 pl-4 space-x-1  ">
          <div>
            <h2 className="text-cl-delivered font-semibold font-bold text-xl">
              Order Delivered Successfully.
            </h2>
          </div>
        </div>
        <div className="text-l pl-5 pb-5 font-thin">
          <p className="text-white font-bold pt-2">
            Enjoy your meal,boss 😋!
          </p>
        </div>
        {/* After preparing starts START */}
        {/* <div className='text-xs pl-5 pb-5 font-thin'>
                        <progress className="progress progress-warning w-62 pr-3" value="40" max="100"></progress>
                        <p className='text-white font-medium pt-2'>Order will arrive in around <span className='text-warning font-semibold'>20 min</span>. 8:24-8:32 PM</p>
                    </div> */}
        {/* After preparing starts START */}
      </div>
    )
}

export default DeliverySuccess;
