import driver from "./images/driver.png";
import RestroModal from "./restroModal";
const Restraurant = (props) => {
  const imgBase = "https://bodia.in/Bodia/AdminApi/uploads/restro/";
  return (
    <div>
     

      <div class="bg2 space-y-1 m-4 p-1 rounded-lg ">
        <h2 className="pl-4 pt-4 font-bold text">
          Order-Id: #{props.order.id}
        </h2>
        <div class="flex font-semibold   text-black p-3 space-x-2 ">
          <div className="w-16">
            <img
              className="mask mask-squircle " width="50px" height="50px"
              src={imgBase + props.order.restroImg}
            />
          </div>
          <p className="text-black text font-semibold pt-2">
            <span className="text-black font-extrabold text">
              {props.order.restroName}
            </span>
          </p>
          {/* <div className="mt-1">
            <a href={`tel:+91 ${props.order.restroPhone}`}>
              <button class="btn btn-success btn-xs">Call</button>
            </a>
          </div> */}
        </div>
        {/* Order Information START */}
        <div className="">
          <h3 className="pt-4 pl-4 font-bold">Order Details</h3>
          <div>
            <div className="flex justify-between pr-4">
              <div>
                <h4 className=" font-semibold pt-4 pl-4 pb-1">
                  1 X {props.order.orderItems[0].quantity}{" "}
                  {props.order.orderItems[0].name}
                </h4>
              </div>
              <div>
                <h4 className=" pt-4 pl-4 font-semibold">
                  ₹{props.order.orderItems[0].total}
                </h4>
              </div>
            </div>
          </div>
          <div className="justify-between flex p-4">
            <div className="text-see text-xs pt-1">
              Check your full order here.
            </div>
            <div>
             <RestroModal order={props.order}/>
              <label htmlFor="my-modal-3" className="btn-xs btn btn-active ">View more</label>
            </div>
          </div>
        </div>
        {/* Order Information END */}
      </div>
    </div>
  );
};

export default Restraurant;
