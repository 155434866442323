import React, {  } from 'react';
import Map from './MapView';
// import './Tracking.css'
// import Header from './Header';
import OrderDetails from './OrderDetails';
import { useParams } from 'react-router-dom';
const Tracking = () => {
    const mainData=(orderData)=>{
            console.log("IN tracking main")
            console.log()
    }
    
    const id = useParams();
    return (
        <div className=''>
            {/* <Header></Header> */}
          
            {/* <Map lat={19.2323} lon={89.23}></Map> */}
            <div className=''>
           <OrderDetails orderID={id}/>
            </div>
         
        </div>
    )
}
export default Tracking;
