import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import OrderDetails from "../OrderDetails";
import "./Map.css";
mapboxgl.accessToken =
  "pk.eyJ1IjoiYm9kaWFzIiwiYSI6ImNsZmkyaHhnejI4a2gzd24xZmZhYXFieHgifQ.GdDTPrGLbzapiaM7vwDgvw";

const Map = (props) => {
  // console.log(props.restroLocation);
  const popupRestro = new mapboxgl.Popup({ closeButton: false });
  // popupRestro.setHTML('<b style="margin:5px">Food</b>');
  popupRestro.setHTML(`<b style="margin:5px">Food</b>`);
  const popupUser = new mapboxgl.Popup({ closeButton: false });
  popupUser.setHTML('<b style="margin:5px">You are here</b>');
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(84.7941);
  const [lat, setLat] = useState(19.315);
  const [zoom, setZoom] = useState(12);
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/dark-v11",
      center: [lng, lat],
      zoom: zoom,
      pitch: 20,
      beaning: 2,
    });
    const marker1 = new mapboxgl.Marker()
      .setLngLat([props.location.uLon, props.location.uLat])
      .setPopup(popupUser)
      .addTo(map)
      .togglePopup();
    const marker2 = new mapboxgl.Marker()
      .setLngLat([props.restroLocation.rLon, props.restroLocation.rLat])
      .setPopup(popupRestro)
      .addTo(map)
      .togglePopup();
    //   const geolocate = new mapboxgl.GeolocateControl({
    //     positionOptions: {
    //         enableHighAccuracy: true
    //     },
    //     trackUserLocation: true
    // });
    // map.addControl(geolocate);
    // geolocate.trigger();
    // ,
    // Add navigation control (the +/- zoom buttons)
    // map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    // map.on("move", () => {
    //   setLng(map.getCenter().lng.toFixed(4));
    //   setLat(map.getCenter().lat.toFixed(4));
    //   setZoom(map.getZoom().toFixed(2));
    // });

    // Clean up on unmount
    // return () => map.remove();
  });

  return (
    <div className="ff">
      {/* <div className="sidebar">
Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
</div> */}
      <div>
        <div ref={mapContainer} className="map-container"></div>
      </div>
    </div>
  );
};
export default Map;
