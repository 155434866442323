import moment from "moment";

const CalculateDeliveryTime = (props) => {
  let defaultTime=34;
  const estimatedMinutes = (deliveryTime2) => {
    const quant = props.order.quantity;
    const currentTime = moment().local();
    const deliveryTiime =deliveryTime2;
    // console.log(deliveryTiime);
    // console.log("Current Time ", currentTime);
    const leftMinutes = moment(deliveryTiime).diff(currentTime, "minutes");
    // console.log(leftMinutes, "DUration");
    return leftMinutes;

    // console.log(orderTime);
  };

  const estimatedTime = () => {
    let increasedMinutes=0;
    const quant = props.order.quantity;
    let deliveryTime = moment(props.order.orderTime).utc()
      .local(true)
      .add(defaultTime, "minutes")
     let leftMinutes2= estimatedMinutes(deliveryTime);
     if(leftMinutes2<10 && props.order.orderStatus===2)
        {
          increasedMinutes=12
          defaultTime+=increasedMinutes;
          leftMinutes2=increasedMinutes;
          deliveryTime = moment()
          .local()
          .add(leftMinutes2, "minutes");
          leftMinutes2=estimatedMinutes(deliveryTime)
        }
        else if(leftMinutes2<5 && props.order.orderStatus===3){
          increasedMinutes=5
          defaultTime+=increasedMinutes;
          leftMinutes2=increasedMinutes;
          // console.log("before chabge",deliveryTime.format("hh:mm A"));
          deliveryTime = moment()
          .local()
          .add(leftMinutes2, "minutes");
          // console.log("came in otw",moment(deliveryTime).format("hh:mm A"));
          leftMinutes2=estimatedMinutes(deliveryTime)

        }
    return (<div>
       <progress
            className="progress progress-warning w-62 pr-3"
            value={defaultTime - leftMinutes2}
            max={defaultTime}
          ></progress>
          <p className="text-white font-medium pt-2">
            Order will arrive in around{" "}
            <span className="text-warning font-semibold">
              {leftMinutes2} min.{" "}
            </span>
            {deliveryTime.format("hh:mm A")}
          </p>
    </div>)
    // console.log(orderTime);
  };
  const checkOrderType = () => {
    if (props.order.deliveryType === 2) {
      return <div>
          <p className="text-white font-medium pt-2">
            Order will arrive around {" "}
            <span className="text-warning font-semibold">
              {props.order.deliveryTime} {" "}
            </span>
            on {moment(props.order.deliveryDate).local().format("DD-MM-YYYY")}
          </p>
      </div>;
    } else {
      return (
        <div>
          {estimatedTime()}
        </div>
      );
    }
  };

  return (
    <div className="text-xs pl-5 pb-5 pr-5 font-thin">{checkOrderType()}</div>
  );
};

export default CalculateDeliveryTime;
