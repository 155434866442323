import driver from './images/driver.png';


const Support =(props)=>{
    return(
      <div>
      {/* <input type="checkbox" id="my-modal-6" class="modal-toggle" /> */}
      {/* <div class="modal modal-bottom sm:modal-middle ">
        <div class="modal-box ">
          <h1 class="text-2xl font-bold mb-2 ">Let us know whats wrong?</h1>
          <div>
            
            
                <form onSubmit={}>
                  <div className="form-control w-full max-w-sm ">
                    <label className="label">
                      <span className="label-text">Order Id</span>
                    </label>
                  </div>

                  <div>
                    <label className="label">
                      <span className="label-text">Amount</span>
                    </label>
                  </div>

                  <h3 class="font-bold text-lg">
                    Note: Refund status will be updated.
                  </h3>

                  <div class="modal-action">
                    <label for="my-modal-6" class="btn">
                      Cancel
                    </label>
                    <a
              href
              ={`https://wa.me/917848077260?text=I%20Need%20Help%20on%20my%20Order%20with%20Order-Id:%20%23${props.order.id}`}
            >
              <button class="btn btn-warning btn">Chat</button>
            </a>
                  </div>
                </form>

              
            
          </div>
        </div>
      </div> */}

      <div class="bg2 space-y-1 m-4 p-1 rounded-lg ">
        <h2 className="pl-4 pt-4 font-bold text">Facing any trouble?</h2>
        <div class="flex font-semibold justify-between  text-black p-3 space-x-2 ">
          <div className="w-12">
            <img className="mask mask-squircle " src={driver} />
          </div>
          <p className="text-black text font-semibold ">
            We are one step away.
          </p>
          <div className="mt-1">
            <a
              href
              ={`https://wa.me/917848077260?text=I%20Need%20Help%20on%20my%20Order%20with%20Order-Id:%20%23${props.order.id}`}
            >
              <button class="btn btn-warning btn-sm">Chat</button>
            </a>
            {/* <label for="my-modal-6" class="mt-.5 btn btn-sm btn-primary " >
              Chat
            </label> */}
          </div>
        </div>
      </div>
    </div>
      
    )
}


export default Support;