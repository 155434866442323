import { React, useState, useEffect } from "react";
import "./OrderDetails.css";
import Map from "../MapView";
import Delay from "./Delay";
import DeliveryPartner from "./DeliveryPartner";
import Restraurant from "./Restaurant";
import DeliverySuccess from "./DeliverySuccess";
import NotAssigned from "./NotAssigned";
import Support from './Support';
import CalculateDeliveryTime from './CalculateDeliveryTime';
import restro from "./images/restro.png";
import driver from './images/driver.png'
import clock from "./images/clock.png";
import { ORDER_STATUS } from "./CONSTANST";
import { PacmanLoader,ClockLoader } from "react-spinners";

// import { ReactComponent as clockMotion } from './270.svg';
// import clockMotion from './270.svg';

const OrderDetails = (props) => {
  const imgBase = "https://bodia.in/Bodia/AdminApi/uploads/restro/";
  const [data, setData] = useState([
    // {
    //   id: 371143,
    //   orderItems: [
    //     {
    //       id: "0",
    //       name: "Momo(Fried)",
    //       price: "80",
    //       quantity: "1",
    //       sizePosition: "0",
    //       sizeTitle: "",
    //       total: "80.00",
    //       type: "1",
    //     },
    //     {
    //       id: "14528",
    //       name: "Mix Veg Chowmin",
    //       price: "90",
    //       quantity: "1",
    //       sizePosition: "0",
    //       sizeTitle: "",
    //       total: "90.00",
    //       type: "1",
    //     },
    //   ],
    //   subTotal: "170.00",
    //   tax: "0.00",
    //   deliveryFees: "19.00",
    //   discount: "0.00",
    //   grandTotal: "189.00",
    //   orderDate: "2023-01-28T18:30:00.000Z",
    //   orderAddress: {
    //     uLat: "19.286965764909617",
    //     uLon: "84.78735376149416",
    //   },
    //   o_payment_mode: 7,
    //   orderStatus: 4,
    //   restroName: "Sanawalas",
    //   restroPhone: 0,
    //   trb_image: "img_61382782d0f5f.jpeg",
    //   rLon: "84.791950718277",
    //   rLat: "19.2956661054855",
    //   driverName: "Sujit",
    //   driverPhone: "8270617348",
    // },
  ]);

  const orderStatusAction = (data2) => {
    switch (data2.orderStatus) {
      case ORDER_STATUS.WAITING:
        return (
          <div>
            <div class="bg1 space-y-1 m-4 rounded-lg ">
              <div class="flextext-black pt-5 pl-5 pb-4 space-x-2  ">
                <div>
                  <h2 className="text-cl font-semibold text-black font-bold text-2xl">
                    Waiting for confirmation.
                  </h2>
                </div>
              </div>
              {/* After preparing starts START */}
              <div className="text-xs pl-5 pb-5 pr-5 font-thin">
                {/* <progress
                className="progress progress-warning w-62 pr-3"
                value="40"
                max="100"
              ></progress> */}
                {/* <p className="text-white font-medium pt-2">
                Order will arrive in around{" "}
                <span className="text-warning font-semibold">20 min</span>.
                8:24-8:32 PM
              </p> */}
                <progress className="progress progress-warning  w-62 pr-3"></progress>
                <p className="text-white font-medium text-l pt-2">
                  Calculating delivery time.{""}
                  {/* <span className="text-warning font-semibold">20 min</span>.
                8:24-8:32 PM */}
                </p>
              </div>
              {/* After preparing starts START */}
            </div>
            <DeliveryPartner order={data[0]} />
            <Restraurant order={data[0]} />
          </div>
        );
      case ORDER_STATUS.CONFIRM:
        return (
          <div>
           
            <div class="bg1 space-y-1 m-4 rounded-lg ">
              <div class="flextext-black pt-5 pl-5 pb-5 space-x-2  ">
                <div>
                  <h2 className="text-cl font-semibold text-black font-bold text-2xl">
                    Order Confirmed.
                  </h2>
                </div>
              </div>
              {/* After preparing starts START */}
              <div className="text-xs pl-5 pb-5 pr-5 font-thin">
                {/* <progress
                className="progress progress-warning w-62 pr-3"
                value="40"
                max="100"
              ></progress> */}
                <progress className="progress progress-warning w-62 pr-3 "></progress>
                <p className="text-white text-l font-medium pt-2">
                  Calculating delivery time.{""}
                  {/* <span className="text-warning font-semibold">20 min</span>.
                8:24-8:32 PM */}
                </p>
              </div>
              {/* After preparing starts START */}
            </div>
            <DeliveryPartner order={data[0]} />
            <Restraurant order={data[0]} />
          </div>
        );
      case ORDER_STATUS.PREPARING:
        return (
          <div>
            <div>
              <div class="bg1 space-y-1 m-4 rounded-lg ">
                <div class="flextext-black pt-5 pl-5  pb-4 space-x-2  ">
                  <div>
                    <h2 className="text-cl font-semibold text-black font-bold text-2xl">
                      Preparing your order.
                    </h2>
                  </div>
                </div>
                {/* Calculating delivery START */}
                <CalculateDeliveryTime order={data[0]}/>
              {/*Calculating delivery  END */}
              </div>

            </div>
            <DeliveryPartner order={data[0]} />
            <Restraurant order={data[0]} />
          </div>
        );
      case ORDER_STATUS.ONTHEWAY:
        return (
          <div>
            <div class="bg1 space-y-1 m-4 rounded-lg ">
              <div class="flextext-black pt-5 pl-5 pb-4 space-x-2  ">
                <div>
                  <h2 className="text-cl font-semibold text-black font-bold text-2xl">
                    On the way.
                  </h2>
                </div>
              </div>
              {/* Calculating delivery START */}
              <CalculateDeliveryTime order={data[0]}/>
              {/*Calculating delivery  END */}
            </div>
            <DeliveryPartner order={data[0]} />
            <Restraurant order={data[0]} />


          </div>
        );
      case ORDER_STATUS.DELIVERED:
        return (
          <DeliverySuccess order={data[0]} />
        );
      case ORDER_STATUS.CANCELLED:
    }
  };

  const fetchTrackingData = async () => {
    // console.log(props.orderID.id);
    //Make API Call here
    const response = await fetch(
      `http://170.187.250.154:8000/api/tracking/${props.orderID.id}`
    );
    const data = await response.json();
    // setData(data2);
    console.log(data);
    return data;
  };
  useEffect(() => {
    fetchTrackingData().then((response) => {
      setData(response);
    });
  }, []);

  return (
    <div className="main">
      {
        !data.length ?
          <div className=" grid flex h-screen justify-center items-center ">
            <div className=""><PacmanLoader size={40}/>
              </div>
          </div>
          :
          <div>
            <Map location={data[0].orderAddress}
              restroLocation={{ rLon: data[0].rLon, rLat: data[0].rLat }} />
	 {/*<Delay></Delay>*/}
            {/*Order Status START*/}
            {orderStatusAction(data[0])}
            {/*Order Status START*/}
            <Support order={data[0]} />
          </div>
      }
    </div>
  );
};

export default OrderDetails;

// <div className="avatar riderboxc">
// <div className="w-12 rounded-xl">
//     <img src={driver} />
// </div>
// <h1 class="text-xl text-black font-bold">
//     <span className='driverName mt-2'>Santosh,</span> will deliver.
// </h1>
// </div>
