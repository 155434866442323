import "./OrderDetails.css";

const Delay =()=>{

    return(
      
        <div class="delaystyle space-y-1 m-5 p-3 rounded-xl text-red font-medium ">
            <h2>Order might be delayed due to heavy demand 🥺.</h2>
            </div>
    
    )



}

export default Delay;