const RestroModal = (props) => {


    return (
        <div>
            {/* Modal Start */}
            <form>
                <div data-theme="">
                    <input type="checkbox" id="my-modal-3" className="modal-toggle" />
                    <div className="modal">
                        <div className="modal-box relative">
                            <label
                                htmlFor="my-modal-3"
                                className="btn btn-sm btn-circle absolute right-2 top-2"
                            >
                                {/* Cross for closing */}
                                ✕
                            </label>
                            <h3 className="text-lg font-bold pb-4">Order Id: #323241</h3>
                            <div className="overflow-x-auto">
                                <table className=" table-compact table-zebra w-full">
                                    {/* <!-- head --> */}
                                    <thead className="active">
                                        <tr>
                                            <th>Items</th>
                                            <th>Unit Cost</th>
                                            <th>Quantity</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.order.orderItems.map((items)=>{
                                            return(
                                                <tr>
                                                <td>{items.name}</td>
                                                <td>₹{items.price}</td>
                                                <td>{items.quantity}</td>
                                                <td>₹{items.total}</td>
                                            </tr>
                                            )
                                        })}
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>Subtotal</td>
                                            <td>₹{props.order.subTotal}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>Tax and Charges</td>
                                            <td>₹{props.order.tax}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>Delivery Charges</td>
                                            <td>₹{props.order.deliveryFees}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>Discount</td>
                                            <td>₹{props.order.discount}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td className="font-bold">Total</td>
                                            <td className="font-bold">₹{props.order.grandTotal}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {/* Modal end */}
        </div>
    )
}


export default RestroModal;