import driver from './images/driver.png';
import NotAssigned from './NotAssigned';
const DeliveryPartner = (props) => {
  const checkDriverAction = (orderData) => {
    switch (orderData.driverAssign) {
      case '':
        return (<div>
          <NotAssigned />
        </div>)
      default:
        return (
          <div class="bg2 space-y-1 m-4 p-1 rounded-lg ">
            <h2 className="pl-4 pt-4 font-bold text">Delivery Partner</h2>
            <div class="flex font-semibold justify-between  text-black p-3 space-x-2 ">
              <div className="w-16">
                <img className="mask mask-squircle " src={driver} />
              </div>
              <p className="text-black text font-semibold ">
                <span className="text-black font-extrabold text">
                  {props.order.driverName}
                </span>
                ,will deliver your order today.
              </p>
              <div className="mt-1">
                <a href={`tel:+91 ${props.order.driverPhone}`}>
                  <button class="btn btn-success btn-xs">call</button>
                </a>
              </div>
            </div>
          </div>)
    }
  }
  // console.log(props.order.driverName);
  return (
    <div>
      {checkDriverAction(props.order)}
    </div>

  )
}

export default DeliveryPartner;
