import driver from './images/driver.png';

const NotAssigned =(props)=>{
    return(
       
              <div class="bg2 space-y-1 m-4 p-1 rounded-lg ">
        
              <h2 className="pl-4 pt-4 font-bold text">Delivery Partner</h2>
              <div class="flex font-semibold justify-between  text-black p-3 space-x-2 ">
                <div className="w-16">
                  <img className="mask mask-squircle " src={driver} />
                </div>
                <p className="text-black text font-semibold ">
                  Delivery partner will be assigned soon.
                </p>
              </div>
            </div>
    )
}

export default NotAssigned;
